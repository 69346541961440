// Utilities & Hooks
import { groupBy, orderBy, uniq } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useDefaultViewSelection } from "../../hooks/useDefaultViewSelection";
import { useApplicationsPositionFiltering } from "../../components/Applications/hooks/useApplicationsPositionFiltering";
import { useApplicationsSorting } from "../../components/Applications/hooks/useApplicationsSorting";
import {
  handleFindNextApplicationWithResume,
  handleFindPreviousApplicationWithResume,
} from "../../components/Applications/utils/handleApplicationsWithResume";
import { useApplicationsGet } from "../../api/Applications/Applications";
import { handleApplicantCityAndState } from "../../utilities/strings/handleApplicantCityAndState";
import { matchSorter } from "match-sorter";
import { handleExportSelectedCSV } from "../../components/Applications/utils/handleExportSelectedCSV";
import {
  handleMergeSearchParameters,
  useExtractSearchParameters,
} from "../../hooks/useExtractSearchParameters";
import { format } from "date-fns";
import useFindTargetedBucket from "../../components/Applications/Buckets/useFindTargetedBucket";
import handleDataPagination from "../../utilities/data/handleDataPagination";
import handleErrorMessage from "../../utilities/handleErrorMessage";
import handlePhoneStringSanitization from "../../utilities/strings/handlePhoneStringSanitization";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";
import { useAuth } from "../../providers/auth-context";

// Components
import Buckets from "../../components/Buckets/Buckets";
import ApplicationCardList from "../../components/Applications/ApplicationCardList";
import ApplicationCardGrid from "../../components/Applications/ApplicationCardGrid";
import Button from "../../components/Button/Button";
import Pagination from "../../components/Pagination/Pagination";
import ApplicationFilters from "../../components/Applications/ApplicationFilters";
import LoaderRefetch from "../../components/Loader/LoaderRefetch";
import ApplicationCardGridSkeletons from "../../components/Applications/Skeletons/ApplicationCardGridSkeletons";
import ApplicationCardListSkeletons from "../../components/Applications/Skeletons/ApplicationCardListSkeletons";
import ViewActions from "../../components/ViewActions/ViewActions";
import ContentHeader from "../../components/Content/ContentHeader";
import ApplicationsSort from "../../components/Applications/ApplicationsSort";
import ApplicationsSelectionIndicator from "../../components/Applications/ApplicationsSelectionIndicator";
import Loader from "../../components/Loader/Loader";
import ApplicationModalResumePreview from "../../components/Applications/modals/ApplicationModalResumePreview";
import ApplicationsHideSelection from "../../components/Applications/ApplicationsHideSelection";

// Interfaces
import { ApplicationsResponseFields } from "../../api/Applications/interfaces";
import {
  ApplicationDetailsMappedFields,
  ApplicationsGroupedByBucket,
} from "../../components/Applications/interfaces";
import { UserRoleNames } from "../../interfaces/global";

// Framer Motion
import { motion, AnimatePresence } from "framer-motion";
import { FRAMER_SELECTION_INDICATOR } from "../../constants/framer";
import ProductTourWrapper from "../../components/ProductTour/ProductTourWrapper";
import { handleTourTooltipPlacement } from "../../components/ProductTour/utilities";
import { useTour } from "../../providers/tour-context";
import { useShouldShowTour } from "../../components/ProductTour/useShouldShowTour";
import handleToursFakeData from "../../components/ProductTour/tours-data/handler";
import Toggle from "../../components/Toggle/Toggle";

const Applications = () => {
  const { user } = useAuth();
  const { isTourRunning } = useTour();

  /*============================
    VIEW TYPE (GRID vs CLASSIC)
  ==============================*/
  const [activeView, handleViewSelection] = useDefaultViewSelection();

  /*============================
    PAGINATION
  ==============================*/
  const COUNT_PER_PAGE: number = 20;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);

  /*============================
    APPLICATIONS FILTERING BY POSITION
  ==============================*/
  const [applicationPositionFilter, handleApplicationPositionFilter] =
    useApplicationsPositionFiltering();

  /*============================
    APPLICATIONS FILTERS
    (SBCA, Resume, LinkedIn)
  ==============================*/
  const [applicationsFilters, setApplicationsFilters] = useState<string[]>([]);

  const handleApplicationsFilters = (values: string[]) => setApplicationsFilters(values);

  /*============================
    APPLICATIONS FILTERING BY BUCKET
  ==============================*/
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  const [applicationsBucketFilter, setApplicationsBucketFilter] = useState<string>(
    searchParametersObject.bucket || "Current",
  );

  const handleBucketFilter = (bucket: string) => setApplicationsBucketFilter(bucket);

  /*============================
    APPLICATIONS SORTING
  ==============================*/
  const [applicationsSortBy, applicationsSortDirection, handleApplicationsSort] =
    useApplicationsSorting();

  /*============================
    APPLICATIONS SEARCHING
  ==============================*/
  const [applicationsSearch, setApplicationsSearch] = useState<string>("");
  const handleApplicationsSearch = (searchedValue: string) => {
    setApplicationsSearch(searchedValue);
    setCurrentPage(0);
  };

  /*============================
    APPLICANTS AI SUMMARY TOUR
  ==============================*/
  const [completeAiSummaryProfile, setCompleteAiSummaryProfile] =
    useState<ApplicationDetailsMappedFields | null>(null);
  const shouldShowApplicantsAiTour = useShouldShowTour("applicants-ai-summary");
  const tourNavigationParameters = handleMergeSearchParameters(searchParametersObject);

  /*============================
    APPLICATIONS DATA
  ==============================*/
  const [showAllApplicationsData, setShowAllApplicationsData] = useState<boolean>(
    searchParametersObject?.all_data === "true" ? true : false,
  );
  const { data, isLoading, isRefetching, isError, error, refetch } =
    useApplicationsGet(showAllApplicationsData);

  const handleShowAllApplicationsData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const toggle_all_data = event.target.checked;
    setShowAllApplicationsData(toggle_all_data);

    // Reset the currently active selection of applications
    setSelectedApplicationsIDs([]);

    // Reset the pagination when viewing "Recent" data
    // Note: Pagination is zero based
    if (!toggle_all_data) setCurrentPage(0);

    // Update the URL parameters
    setSearchParametersObject({
      ...searchParametersObject,
      ...(!toggle_all_data && { page: 1 }),
      all_data: toggle_all_data,
    });
  };

  const APPLICATIONS = useMemo(() => {
    // Return default empty array if there's no data available
    if (!data || !data.length || isLoading) {
      return {
        MAPPED_DATA: [],
        UNFILTERED_DATA: [],
      };
    }

    // Map the received data, extracting only fields that we need
    // to be displayed in the UI
    let MAPPED_DATA: ApplicationDetailsMappedFields[] = data.map(
      (application: ApplicationsResponseFields) => {
        return {
          id: application.id,
          buckets: application.buckets,
          registration_date: application.registration_date ?? "",
          sbca: application.applicant.sbca ?? null,
          sbca_report_identifier: application.applicant.sbca_report_identifier ?? null,
          sbca_request_date: application.applicant.sbca_request_date ?? "",
          appointments_count: application.appointments_count ?? 0,
          comments_count: application.comments_count ?? null,
          comments_avg_rating: application.comments_avg_rating
            ? +application.comments_avg_rating.toFixed(2)
            : null,
          applicant_id: application.applicant.id,
          name: handleFullnameCombination(application.applicant),
          photo: application.applicant.photo ?? "",
          hired_date: application.hired_date,
          position: application.position ?? "",
          location: handleApplicantCityAndState(
            application.applicant.city,
            application.applicant.state,
          ),
          phone: handlePhoneStringSanitization(application.applicant.phone),
          text_message_consent: application.text_message_consent ?? false,
          resume: application.resume ?? "",
          resume_request_date: application.resume_request_date ?? "",
          linkedin: application.linkedin ?? "",
          email: application.applicant.email,
          schedule_meeting: application.schedule_meeting,
          idOfNextApplicationWithResume: null,
          idOfPreviousApplicationWithResume: null,
          meeting_link: application.meeting_link,
          source: application.source,
          is_hidden: application.is_hidden,
          has_ai_sbca_summary: application.applicant.has_ai_sbca_summary,
          has_ai_resume_summary: false,
          sorting_date: application.hired_date
            ? format(new Date(application.hired_date), "yyyy-MM-dd")
            : format(new Date(application.registration_date), "yyyy-MM-dd"),
        };
      },
    );

    // Unfiltered Data where all applications are accessible
    const UNFILTERED_DATA: ApplicationDetailsMappedFields[] = [...MAPPED_DATA];

    // Filter the list of applications based on
    // the currently active bucket
    if (applicationsBucketFilter.toLowerCase() !== "all") {
      MAPPED_DATA = MAPPED_DATA.filter(application => {
        return application.buckets.some(bucket => {
          return bucket.toLowerCase() === applicationsBucketFilter.toLowerCase();
        });
      });
    }

    // Filter the list of applications based on
    // the position that they applied for
    if (applicationPositionFilter !== "all") {
      MAPPED_DATA = MAPPED_DATA.filter(application => {
        return application.position.toLowerCase() === applicationPositionFilter.toLowerCase();
      });
    }

    // Filter the list of applications based on
    // the selected socials (Resume, LinkedIn, SBCA) filters
    if (applicationsFilters.length) {
      MAPPED_DATA = MAPPED_DATA.filter((application: any) => {
        return applicationsFilters.every(socialFilter => application[socialFilter]);
      });
    }

    // Search trough the list of applications based on "name", "position" or "location"
    if (applicationsSearch) {
      MAPPED_DATA = matchSorter(MAPPED_DATA, applicationsSearch, {
        keys: ["name"],
        threshold: matchSorter.rankings.CONTAINS,
      });
    }

    // Sort the list of applications based on the selected sorting value and direction.
    // Applications can be sorted either by "Date" or "Score", and either in "ascending" or "descending" order

    // NOTE: The following ordering function first checks for all falsey values (e.g. null, undefined)
    // and moves them to the END of the list. Then, the remaining valid values are ordered based on the
    // selected value and direction, which results all of the valid values (either in "asc" or "desc" order)
    // to always show first in the list, and all the invalid values will always go at the end of the list.

    MAPPED_DATA = orderBy(
      MAPPED_DATA,
      [
        applicationCheck => {
          return applicationCheck[applicationsSortBy] == null;
        },
        application => {
          // Sort the applications by SBCA Score
          if (applicationsSortBy === "sbca") {
            return application.sbca ? application.sbca : "";
          }

          // When viewing any of the buckets other than "All" or "Hired"
          // use the application registration date for sorting
          if (!["All", "Hired"].includes(applicationsBucketFilter)) {
            return application.registration_date ? application.registration_date : "";
          }

          // When viewing the "Hired" bucket use the application hiring date for sorting
          if (applicationsBucketFilter === "Hired") {
            return application.hired_date ? application.hired_date : "";
          }

          // When viewing "All" bucket use the custom sorting date field which contains a value that
          // either represents the hiring date (if there's one) or the registraion date of the application.
          // This custom sorting date field is created while mapping the data.
          if (applicationsBucketFilter === "All") {
            return application.sorting_date || "";
          }
        },
      ],
      ["asc", applicationsSortDirection],
    );

    return { MAPPED_DATA, UNFILTERED_DATA };
  }, [
    data,
    currentPage,
    applicationPositionFilter,
    applicationsFilters,
    applicationsSortBy,
    applicationsSortDirection,
    applicationsBucketFilter,
    applicationsSearch,
  ]);

  // The mapping of the paginated data is separated from the main 'APPLICATIONS' data mapping
  // so it can be used in multiple places for different needs (e.g. to get the length of the currently filtered data only)
  const APPLICATIONS_PAGINATED_DATA = useMemo(() => {
    // Total number of pages
    const totalPages: number = Math.ceil(APPLICATIONS.MAPPED_DATA.length / COUNT_PER_PAGE);
    setTotalPagesCount(totalPages);

    // Paginate the mapped data
    let mappedPaginatedData = handleDataPagination<ApplicationDetailsMappedFields>(
      APPLICATIONS.MAPPED_DATA,
      currentPage,
      COUNT_PER_PAGE,
    );

    // If there's fake tour data to be shown based on currently active feature tour,
    // add that fake data entry to the current paginated data
    const tourFakeData = handleToursFakeData(user, "applicants-ai-summary") as
      | ApplicationDetailsMappedFields
      | undefined;

    if (!isLoading && tourFakeData) {
      const typecastedTourFakeData = tourFakeData as Record<string, any>;
      mappedPaginatedData.unshift(typecastedTourFakeData.listEntry);
    }

    // Find applicant that has a complete AI summary profile from
    // the currently paginated applications data.
    // A "complete profile" represents a profile that has both
    // "Resume" and "SBCA" generated ai summary.
    const profileWithCompleteAISummary = mappedPaginatedData.filter(data => {
      return data.has_ai_sbca_summary && data.has_ai_resume_summary;
    });

    if (profileWithCompleteAISummary.length) {
      setCompleteAiSummaryProfile(profileWithCompleteAISummary[0]);
    } else {
      setCompleteAiSummaryProfile(null);
    }

    // We map trough the paginated dataset once again to add the
    // fields for each individual application that contain the ID of the
    // "previous" or "next" application that has a resume
    mappedPaginatedData = mappedPaginatedData.map(applicationsData => {
      return {
        ...applicationsData,
        idOfPreviousApplicationWithResume: handleFindPreviousApplicationWithResume(
          mappedPaginatedData,
          applicationsData.id,
        ),
        idOfNextApplicationWithResume: handleFindNextApplicationWithResume(
          mappedPaginatedData,
          applicationsData.id,
        ),
      };
    });

    return mappedPaginatedData;
  }, [APPLICATIONS, currentPage, isLoading, isTourRunning]);

  /*============================
    APPLICATIONS SELECTION
  ==============================*/
  const [selectedApplicationsIDs, setSelectedApplicationsIDs] = useState<number[]>([]);

  const handleResetSelection = () => setSelectedApplicationsIDs([]);

  // Selects OR deselects all the applications on the current page
  // Adds the currently shown applicants to the selection OR removes all shown from the selection
  const handleApplicationsAllSelection = () => {
    const APPLICATIONS_MAPPED_IDS = APPLICATIONS_PAGINATED_DATA.filter(
      application => !application.is_hidden,
    ).map(application => application.id);
    const SELECTED_SHOWN_OVERLAPPING = APPLICATIONS_MAPPED_IDS.every(element => {
      return selectedApplicationsIDs.includes(element);
    });

    // If all currently shown applications are selected, remove only those
    // that overlap between the current page selection and the selection from the entire dataset
    if (SELECTED_SHOWN_OVERLAPPING) {
      setSelectedApplicationsIDs(
        selectedApplicationsIDs.filter(selectedID => !APPLICATIONS_MAPPED_IDS.includes(selectedID)),
      );
    } else {
      // Else spread the existing old selections and add the new ones to the array
      setSelectedApplicationsIDs(uniq([...selectedApplicationsIDs, ...APPLICATIONS_MAPPED_IDS]));
    }
  };

  // Selects OR deselects individual applications
  const handleIndividualApplicationsSelection = (applicationID: number) => {
    let applicationsIDs: number[] = [...selectedApplicationsIDs];
    const isApplicationAlreadySelected: number = applicationsIDs.findIndex(
      id => id === applicationID,
    );

    if (isApplicationAlreadySelected < 0) {
      applicationsIDs.push(applicationID);
    } else {
      applicationsIDs = applicationsIDs.filter(id => id !== applicationID);
    }

    setSelectedApplicationsIDs(applicationsIDs);
  };

  // Reset the array of selected application IDs anytime the active bucket changes
  useEffect(() => {
    handleResetSelection();
  }, [applicationsBucketFilter]);

  /*=======================================
    GROUP SELECTED APPLICATIONS BY BUCKETS

    Used for "Undo" actions after a bulk update
  ========================================*/
  const [handleFindTargetedBucket] = useFindTargetedBucket();
  const [groupedApplicationBuckets, setGroupedApplicationBuckets] =
    useState<ApplicationsGroupedByBucket>({});

  useEffect(() => {
    // If there are no selected applications clear out the selected buckets state
    if (!selectedApplicationsIDs.length) {
      setGroupedApplicationBuckets({});
      return;
    }

    // Extract the application and its bucket details
    const extractedApplicationsDetails = APPLICATIONS.MAPPED_DATA.filter(application => {
      return selectedApplicationsIDs.some(selectedApplicationID => {
        return selectedApplicationID === application.id;
      });
    }).map(filteredApplication => {
      return {
        id: filteredApplication.id,
        application_name: filteredApplication.name,
        bucket_id: handleFindTargetedBucket(filteredApplication.buckets[0]) || null,
      };
    });

    // Group the selected buckets by their bucket IDs
    const groupedApplicationsData = groupBy(extractedApplicationsDetails, "bucket_id");

    // Clear out any applications that are grouped by 'null' which means
    // they didnt have a bucket at the moment of selection
    Object.keys(groupedApplicationsData).forEach(key => {
      if (JSON.parse(key) === null) delete groupedApplicationsData[key];
    });

    setGroupedApplicationBuckets(groupedApplicationsData);
  }, [selectedApplicationsIDs]);

  // Filter out the data for the currently active bucket only & map the ids for the ApplicationsSelectionIndicator component
  const BUCKET_APPLICATIONS_IDS = useMemo(() => {
    // Exit function if there's no data to work with
    if (!data) return [];

    // Extract the IDs of the applications that will be
    // displayed in the dashboard, based on selected bucket
    if (applicationsBucketFilter === "All") {
      return data.filter(application => !application.is_hidden).map(application => application.id);
    } else {
      return data
        .filter(application => !application.is_hidden)
        .filter(application => application.buckets.includes(applicationsBucketFilter))
        .map(application => application.id);
    }
  }, [applicationsBucketFilter, data]);

  /*=======================================
    APPLICATION RESUME PREVIEW DETAILS
  ========================================*/
  const [openedApplicationDetails, setOpenedApplicationDetails] =
    useState<ApplicationDetailsMappedFields | null>(null);

  /*=======================================
    FOCUS ON THE APPLICATION WHOSE
    RESUME WE VIEWED LAST
  ========================================*/
  const [focusedApplicationDetails, setFocusedApplicationDetails] = useState<number | undefined>(
    undefined,
  );
  const handleCloseResumePreviewer = (applicantId: number | undefined) => {
    // Focus on the applicant whose resume we viewed last
    // while clearing out the details that were used to populate the previewer
    setFocusedApplicationDetails(applicantId);
    setOpenedApplicationDetails(null);

    // Clear out the state so focus can be cleared too
    setTimeout(() => setFocusedApplicationDetails(undefined), 500);
  };

  return (
    <>
      <Buckets
        selectedBucket={applicationsBucketFilter}
        handleBucketFilter={handleBucketFilter}
        handlePaginationReset={() => setCurrentPage(0)}
      />
      <ApplicationFilters
        applicationsIDs={selectedApplicationsIDs}
        currentBucketFilter={applicationsBucketFilter}
        hasActiveSelection={selectedApplicationsIDs.length > 0}
        hasAllApplicationsSelected={
          APPLICATIONS.MAPPED_DATA.length > 0 &&
          APPLICATIONS.MAPPED_DATA.map(application => application.id).every(application => {
            return selectedApplicationsIDs.includes(application);
          })
        }
        groupedApplicationBuckets={groupedApplicationBuckets}
        filtersDisabled={isLoading || isError}
        handleApplicationsSearch={handleApplicationsSearch}
        handleApplicationsPositionFilter={handleApplicationPositionFilter}
        handleApplicationsFilters={handleApplicationsFilters}
        handleApplicationsAllSelection={handleApplicationsAllSelection}
        handleApplicationsResetSelection={() => handleResetSelection()}
        handlePaginationReset={() => setCurrentPage(0)}
        handleExportSelected={() =>
          handleExportSelectedCSV(
            data?.filter(application => selectedApplicationsIDs.includes(application.id)),
          )
        }
      />

      <AnimatePresence>
        {selectedApplicationsIDs.length ? (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={FRAMER_SELECTION_INDICATOR}
            transition={{ type: "spring", duration: 0.5, damping: 25 }}
          >
            <ApplicationsSelectionIndicator
              selectedApplicationsIDs={selectedApplicationsIDs}
              paginatedApplications={APPLICATIONS_PAGINATED_DATA.filter(
                application => !application.is_hidden,
              ).map(application => application.id)}
              bucketApplications={BUCKET_APPLICATIONS_IDS}
              applicationsBucketFilter={applicationsBucketFilter}
              isDataFiltered={
                !!(
                  applicationPositionFilter !== "all" ||
                  applicationsFilters.length ||
                  applicationsSearch
                )
              }
              filteredApplications={APPLICATIONS.MAPPED_DATA.filter(
                application => !application.is_hidden,
              ).map(application => application.id)}
              setSelectedApplicationsIDs={setSelectedApplicationsIDs}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>

      <div className="container py--25">
        <ContentHeader
          title={
            <>
              Applications - <span className="txt--capitalize">{applicationsBucketFilter}</span>
              {isLoading || isRefetching ? <Loader modifierWrapper="ml--10" size="xs" /> : null}
            </>
          }
          modifierClass="content__header--no-underline mb--10 pb--0i"
        >
          <AnimatePresence>
            {user.role === UserRoleNames.SUPER_ADMIN ? (
              <ApplicationsHideSelection
                selectedApplicationIDs={selectedApplicationsIDs}
                handleResetSelection={() => handleResetSelection()}
              />
            ) : null}
          </AnimatePresence>

          <ApplicationsSort
            sortBy={applicationsSortBy}
            sortDirection={applicationsSortDirection}
            handleApplicationsSort={handleApplicationsSort}
          />

          <ViewActions
            activeView={activeView}
            handleViewSelection={handleViewSelection}
            modifierClass="w--unset ml--20 ml--md--0"
          />
        </ContentHeader>

        {isRefetching && <LoaderRefetch title="Refetching Applications..." />}

        <div className="d-flex flex-column mb--20">
          <Toggle
            id={"all-applications"}
            name={"all-applications"}
            textLeft={"Recent"}
            textRight="All"
            isToggled={showAllApplicationsData}
            handleOnChange={handleShowAllApplicationsData}
            isDisabled={isLoading || isRefetching}
            modifierClass="mb--5"
          />
          {showAllApplicationsData ? (
            <p className="txt--md mb--0">Showing all existing applications.</p>
          ) : (
            <p className="txt--md mb--0">
              Recent data will show the applications from the last 1 year.
            </p>
          )}
        </div>

        {isLoading ? (
          activeView === "grid" ? (
            <ApplicationCardGridSkeletons />
          ) : (
            <ApplicationCardListSkeletons />
          )
        ) : isError ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h4 className="mb--20">{handleErrorMessage(error)}</h4>

            <Button
              modifierClass="btn--fluid btn--fluid--md btn--primary"
              onClick={() => refetch()}
            >
              Refetch Applications
            </Button>
          </div>
        ) : APPLICATIONS_PAGINATED_DATA.length === 0 ? (
          <div className="d-flex justify-content-center">
            <h4>
              {/* Check if there's an active search filter */}
              {applicationPositionFilter !== "all" ||
              applicationsFilters.length ||
              applicationsSearch
                ? "No Applications exist that match the selected filters."
                : "No Applications found."}
            </h4>
          </div>
        ) : activeView === "grid" ? (
          <div className="row gutters-sm">
            {APPLICATIONS_PAGINATED_DATA.map((details: ApplicationDetailsMappedFields) => (
              <ApplicationCardGrid
                key={`application-card-grid-${details.id}`}
                details={details}
                isFocused={focusedApplicationDetails === details.id}
                selectedApplications={selectedApplicationsIDs}
                activeBucket={applicationsBucketFilter}
                handleApplicationsSelection={handleIndividualApplicationsSelection}
                handleApplicationResumePreview={details => setOpenedApplicationDetails(details)}
              />
            ))}
          </div>
        ) : (
          <div>
            {APPLICATIONS_PAGINATED_DATA.map((details: ApplicationDetailsMappedFields) => (
              <ApplicationCardList
                key={`application-card-list-${details.id}`}
                details={details}
                isFocused={focusedApplicationDetails === details.id}
                activeBucket={applicationsBucketFilter}
                selectedApplications={selectedApplicationsIDs}
                handleApplicationsSelection={handleIndividualApplicationsSelection}
                handleApplicationResumePreview={details => setOpenedApplicationDetails(details)}
              />
            ))}
          </div>
        )}
        {totalPagesCount > 1 && (
          <div className="d-flex justify-content-end my--20">
            <Pagination
              pageCount={totalPagesCount}
              currentPage={currentPage}
              handlePageChange={({ selected }) => setCurrentPage(selected)}
            />
          </div>
        )}

        {openedApplicationDetails && (
          <ApplicationModalResumePreview
            id={openedApplicationDetails.id}
            allApplications={APPLICATIONS.UNFILTERED_DATA}
            allPaginatedApplications={APPLICATIONS_PAGINATED_DATA}
            handleCloseModal={handleCloseResumePreviewer}
          />
        )}
      </div>

      {/* TOUR_FLAG[applicants-ai-summary] */}
      {shouldShowApplicantsAiTour && completeAiSummaryProfile ? (
        <ProductTourWrapper
          tourToShow={"applicants-ai-summary"}
          isLoading={isLoading}
          dynamicTour={{
            tourId: "applicants-ai-summary",
            type: "manual",
            steps: [
              {
                title: "Enhanced Applicant Profile",
                target: ".applications--complete-ai-profile",
                content: (
                  <div>
                    We've improved job seeker profiles. Powered by AI, FirstChoice now generates key
                    hiring insights and suggested interview questions based on each applicants
                    unique employment experience and assessment results.
                  </div>
                ),
                disableBeacon: true,
                placement: handleTourTooltipPlacement({
                  lg: "top",
                  md: "top",
                  sm: "top",
                  xs: "top",
                }),
                stepName: "applicants-ai-summary complete profile",
                data: {
                  next: {
                    link: `/tours/applicants/ai-summary/${tourNavigationParameters}`,
                  },
                },
                floaterProps: {
                  disableAnimation: true,
                },
              },
              {
                title: "Improved Details Page",
                target: "#applicant-details-wrapper",
                content: (
                  <div>
                    Click the down arrow for each section to reveal detailed job seeker information.
                  </div>
                ),
                placement: handleTourTooltipPlacement({
                  lg: "right",
                  md: "top",
                  sm: "top",
                  xs: "top",
                }),
                disableBeacon: true,
                stepName: "applicants-ai-summary improved details page",
                data: {
                  prev: {
                    link: `/applications/${tourNavigationParameters}`,
                  },
                },
                floaterProps: {
                  disableAnimation: true,
                },
              },
              {
                title: "Applicant Summary Information",
                target: "#accordion-applicant-summary",
                content: (
                  <div>
                    Open the applicant summary to reveal an analysis of strengths, areas of concern,
                    and positions each job seeker may excel in, based on their unique experience.
                  </div>
                ),
                placement: handleTourTooltipPlacement({
                  lg: "right-start",
                  md: "auto",
                  sm: "auto",
                  xs: "auto",
                }),
                disableBeacon: true,
                stepName: "applicants-ai-summary resume summary",
                floaterProps: {
                  disableAnimation: true,
                },
              },
              ...(!user.active_company.disable_sbca
                ? [
                    {
                      title: "SBCA Results",
                      target: "#accordion-sbca-results",
                      content: (
                        <div>
                          Open the SBCA results to reveal each job seeker's assessment score, a
                          summary detailing key qualities discovered and their potential fitness for
                          a specific role.
                        </div>
                      ),
                      placement: handleTourTooltipPlacement({
                        lg: "right-start",
                        md: "auto",
                        sm: "auto",
                        xs: "auto",
                      }),
                      disableBeacon: true,
                      stepName: "applicants-ai-summary sbca summary",
                      floaterProps: {
                        disableAnimation: true,
                      },
                    },
                  ]
                : []),
              {
                title: "Interview Questions",
                target: "#accordion-interview-questions",
                content: (
                  <div>
                    Open the interview questions dropdown to reveal suggested questions based on
                    each job seeker's unique career experience and assessment results.
                  </div>
                ),
                placement: handleTourTooltipPlacement({
                  lg: "right-start",
                  md: "auto",
                  sm: "auto",
                  xs: "auto",
                }),
                disableBeacon: true,
                stepName: "applicants-ai-summary interview questions",
                floaterProps: {
                  disableAnimation: true,
                },
              },
            ],
          }}
        />
      ) : null}
    </>
  );
};

export default Applications;
