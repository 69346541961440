// Utilities & Hooks
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash-es";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth-context";
import fetchHandler from "../fetchHandler";
import { format } from "date-fns";

// Interfaces
import {
  ApplicantHistoryResponseFields,
  ApplicantRegenerateAiResumeSummaryData,
  ApplicantRegenerateAiSbcaSummaryData,
  ApplicationDetailsFields,
  ApplicationRequestResumePayload,
  ApplicationsMoveToBucketRequestFields,
  ApplicationsRequestSBCAPayload,
  ApplicationsResponseFields,
  ApplicationsResumeResponseFields,
  ApplicationsShareByEmailPayload,
  ApplicationsSourcesFields,
  ApplicationsUndoBucketMovementRequestFields,
} from "./interfaces";
import { BucketsResponseFields } from "../Buckets/interfaces";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";

/**
 *
 * Get a list of all existing Applications for
 * the currently active company.
 *
 */
export function useApplicationsGet(all_data: boolean = false) {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  // Remove the query that contains all data from the cache
  // to free up memory and avoid potential performance degradation
  // Will cause data refetch when user toggles "All" option on
  if (!all_data) {
    queryClient.removeQueries({
      queryKey: ["applications", companySlug, { all_data: true }],
    });
  }

  return useQuery(
    ["applications", companySlug, { all_data }],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/applications?all=${JSON.stringify(all_data)}`,
      )) as ApplicationsResponseFields[];
    },
    { enabled: !!companySlug },
  );
}

/**
 *
 * Get the details for a specific application
 *
 * @param applicationID The ID of the targeted application
 *
 */
export function useApplicationsGetSpecific(applicationID: string | undefined) {
  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["applications-details", applicationID, companySlug],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/applications/${applicationID}`,
      )) as ApplicationDetailsFields;
    },
    { enabled: !!applicationID && !!companySlug },
  );
}

/**
 *
 * Move the selected applications to the specified bucket.
 *
 * The mutation hook's `data` parameter is an object consisting of two properties:
 * - `bucket_id` to which the application will be moved
 * - `application_ids` array of IDs of the applications that will be moved
 *
 */
export function useApplicationsMoveToBucket() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  // Handle the query key responsible for viewing "All" or "Recent" data
  const [searchParametersObject] = useExtractSearchParameters();
  const all_data = searchParametersObject?.all_data === "true" ? true : false;

  return useMutation(
    async (data: ApplicationsMoveToBucketRequestFields) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/buckets/${data.bucket_id}/applications`,
        { application_ids: data.application_ids },
      );
    },
    {
      onMutate: data => {
        /*===============================
          APPLICATIONS DATA
        ================================*/
        const cachedApplicationsData = queryClient.getQueryData([
          "applications",
          companySlug,
          { all_data },
        ]) as ApplicationsResponseFields[];

        if (cachedApplicationsData) {
          // Clone the existing query data
          const updatedApplications = cloneDeep(cachedApplicationsData);

          // Go trough each of the selected application's to and update their cached data
          data.application_ids.forEach(id => {
            const targetedApplicationIndex: number = cachedApplicationsData.findIndex(
              application => {
                return application.id === id;
              },
            );

            // Prevent updates to the cached data if application canno't be found for some reason
            if (targetedApplicationIndex < 0) return;

            // Set/Remove "hired_date" field IF destination OR source bucket is "Hired"
            if (data.bucket_name === "Hired") {
              updatedApplications[targetedApplicationIndex].hired_date = format(
                new Date(Date.now()),
                "MM/dd/yyyy",
              );
            } else if (updatedApplications[targetedApplicationIndex].buckets[0] === "Hired") {
              updatedApplications[targetedApplicationIndex].hired_date = null;
            }

            // Update each individual application's buckets property
            updatedApplications[targetedApplicationIndex].buckets = [data.bucket_name];
          });

          // Update the cached data
          queryClient.setQueryData(
            ["applications", companySlug, { all_data }],
            updatedApplications,
          );
        }

        /*===============================
          INDIVIDUAL APPLICATION DETAILS
        ================================*/
        const cachedApplicationDetailsData = queryClient.getQueryData([
          "applications-details",
          data.application_ids[0].toString(),
          companySlug,
        ]) as ApplicationDetailsFields;

        if (cachedApplicationDetailsData) {
          // Clone the existing data
          const updatedApplicationDetails = cloneDeep(cachedApplicationDetailsData);

          // Set/Remove "hired_date" field IF destination OR source bucket is "Hired"
          if (data.bucket_name === "Hired") {
            updatedApplicationDetails.hired_date = format(new Date(Date.now()), "MM/dd/yyyy");
          } else if (updatedApplicationDetails.bucket[0] === "Hired") {
            updatedApplicationDetails.hired_date = null;
          }
          updatedApplicationDetails.bucket = [data.bucket_name];

          // Update the cached data
          queryClient.setQueryData(
            ["applications-details", data.application_ids[0].toString(), companySlug],
            updatedApplicationDetails,
          );
        }

        return { cachedApplicationsData, cachedApplicationDetailsData };
      },
      onError: (error, data, context) => {
        // If something goes wrong and there's cached "Applications" data, revert it to its original state
        if (context?.cachedApplicationsData) {
          queryClient.setQueryData(
            ["applications", companySlug, { all_data }],
            context.cachedApplicationsData,
          );
        }

        // If something goes wrong and there's cached "Applications Details" data revert it to its original state
        if (context?.cachedApplicationDetailsData) {
          queryClient.setQueryData(
            ["applications-details", data.application_ids[0].toString(), companySlug],
            context.cachedApplicationDetailsData,
          );
        }

        return error;
      },
      onSettled: () => {
        queryClient.invalidateQueries(["appointments", companySlug]);
      },
    },
  );
}

/**
 *
 * Revert (Undo) the application bucket movement to it's original bucket.
 *
 */
export function useApplicationsUndoBucketMovement() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  // Handle the query key responsible for viewing "All" or "Recent" data
  const [searchParametersObject] = useExtractSearchParameters();
  const all_data = searchParametersObject?.all_data === "true" ? true : false;

  return useMutation(
    async (data: ApplicationsUndoBucketMovementRequestFields) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/buckets/${data.bucket_id}/applications`,
        { application_ids: data.application_ids },
      );
    },
    {
      onMutate: data => {
        /*===============================
          BUCKETS DATA
        ================================*/
        const cachedBucketsData = queryClient.getQueryData([
          "buckets",
          companySlug,
        ]) as BucketsResponseFields[];

        // Find the name of the bucket to which we'll revert the application
        const bucket = cachedBucketsData.find(bucket => bucket.id === data.bucket_id);

        // Exit function if there's no bucket found and prevent any actions
        if (!bucket) return;

        /*===============================
          APPLICATIONS DATA
        ================================*/
        const cachedApplicationsData = queryClient.getQueryData([
          "applications",
          companySlug,
          { all_data },
        ]) as ApplicationsResponseFields[];

        if (cachedApplicationsData) {
          // Clone the existing query data
          const updatedApplications = cloneDeep(cachedApplicationsData);

          // Go trough each of the selected application's to and update their cached data
          data.application_ids.forEach(id => {
            const targetedApplicationIndex: number = cachedApplicationsData.findIndex(
              application => {
                return application.id === id;
              },
            );

            // Prevent updates to the cached data if application canno't be found for some reason
            if (targetedApplicationIndex < 0) return;

            // Update each individual application's buckets property
            updatedApplications[targetedApplicationIndex].buckets = [bucket.name];
            if (bucket.name === "Hired") {
              updatedApplications[targetedApplicationIndex].hired_date = format(
                new Date(Date.now()),
                "MM/dd/yyyy",
              );
            } else {
              updatedApplications[targetedApplicationIndex].hired_date = null;
            }
          });

          // Update the cached data
          queryClient.setQueryData(
            ["applications", companySlug, { all_data }],
            updatedApplications,
          );

          // Show success notification
          toast.success("Changes have been reverted!", {
            toastId: "buckets-revert-changes",
          });
        }

        /*===============================
          INDIVIDUAL APPLICATION DETAILS
        ================================*/
        const cachedApplicationDetailsData = queryClient.getQueryData([
          "applications-details",
          data.application_ids[0].toString(),
          companySlug,
        ]) as ApplicationDetailsFields;

        if (cachedApplicationDetailsData) {
          // Clone the existing data
          const updatedApplicationDetails = cloneDeep(cachedApplicationDetailsData);
          updatedApplicationDetails.bucket = [bucket.name];

          if (bucket.name === "Hired") {
            updatedApplicationDetails.hired_date = format(new Date(Date.now()), "MM/dd/yyyy");
          } else {
            updatedApplicationDetails.hired_date = null;
          }

          // Update the cached data
          queryClient.setQueryData(
            ["applications-details", data.application_ids[0].toString(), companySlug],
            updatedApplicationDetails,
          );

          // Show success notification
          toast.success("Changes have been reverted!", {
            toastId: "buckets-revert-changes",
          });
        }

        return { cachedApplicationsData, cachedApplicationDetailsData, bucket };
      },

      onError: (error, data, context) => {
        // Dismiss the success notification from the UI first
        toast.dismiss("buckets-revert-changes");

        // If something goes wrong and there's cached "Applications" data, revert it to its original state
        if (context?.cachedApplicationsData) {
          queryClient.setQueryData(
            ["applications", companySlug, { all_data }],
            context.cachedApplicationsData,
          );
        }

        // If something goes wrong and there's cached "Applications Details" data revert it to its original state
        if (context?.cachedApplicationDetailsData) {
          queryClient.setQueryData(
            ["applications-details", data.application_ids[0].toString(), companySlug],
            context.cachedApplicationDetailsData,
          );
        }

        return error;
      },
      onSettled: () => {
        queryClient.invalidateQueries(["appointments", companySlug]);
      },
    },
  );
}

/**
 *
 * Get the targeted application from the server
 *
 * @param applicationID The targeted application's ID value
 *
 */
export function useApplicationGetResume(applicationID: number | undefined) {
  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["applications-resume", companySlug, applicationID],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/applications/${applicationID}/resume`,
      )) as ApplicationsResumeResponseFields;
    },

    // Only send a request if there's a valid application ID and company slug values to work with
    { enabled: !!applicationID && !!companySlug },
  );
}

/**
 *
 * Request a resume for the selected Application
 *
 */
export function useApplicationRequestResume() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  // Handle the query key responsible for viewing "All" or "Recent" data
  const [searchParametersObject] = useExtractSearchParameters();
  const all_data = searchParametersObject?.all_data === "true" ? true : false;

  return useMutation(
    async (details: ApplicationRequestResumePayload) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/applications/${details.id}/resume/request`,
        { message: details.message },
      );
    },
    {
      onError: error => error,
      onSettled: () => {
        queryClient.invalidateQueries(["applications", companySlug, { all_data }]);
        queryClient.invalidateQueries(["appointments"]);
      },
    },
  );
}

/**
 *
 * Request SBCA for the selected Application
 *
 */
export function useApplicationRequestSBCA() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  // Handle the query key responsible for viewing "All" or "Recent" data
  const [searchParametersObject] = useExtractSearchParameters();
  const all_data = searchParametersObject?.all_data === "true" ? true : false;

  return useMutation(
    async (details: ApplicationsRequestSBCAPayload) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/applications/${details.id}/sbca/request`,
        { message: details.message },
      );
    },
    {
      onError: error => error,
      onSettled: (_data, _error, details) => {
        queryClient.invalidateQueries(["applications", companySlug, { all_data }]);
        queryClient.invalidateQueries(["applications-details", details.id.toString(), companySlug]);
      },
    },
  );
}

/**
 *
 * Share an application from the company
 * to another user by sending an email
 *
 */
export function useApplicationShareByEmail() {
  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (details: ApplicationsShareByEmailPayload) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/applications/${details.applicationID}/share`,
        { recipient_id: details.recipient_id, message: details.message },
      );
    },
    { onError: error => error },
  );
}

/**
 *
 * Marks an application as hidden and moves it to
 * "Archived" bucket automatically.
 *
 * Usable only by Super Admins
 *
 */
export function useApplicationHide() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;
  const queryClient = useQueryClient();

  return useMutation(
    async (applicationId: string) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/applications/${applicationId}/hide`,
      );
    },
    {
      onMutate: applicationId => {
        // Get the targeted application from the cached data
        const cachedApplication = queryClient.getQueryData([
          "applications-details",
          applicationId,
          companySlug,
        ]) as ApplicationDetailsFields;

        // Mark the application as hidden and move it to "Archived" bucket
        const updatedApplication: ApplicationDetailsFields = {
          ...cachedApplication,
          is_hidden: true,
          bucket: ["Archived"],
          hired_date: null,
        };
        queryClient.setQueryData(
          ["applications-details", applicationId, companySlug],
          updatedApplication,
        );

        return { cachedApplication };
      },
      onSuccess: () => toast.success("Application marked as hidden!"),
      onError: (error, applicationId, context) => {
        // Return to previous cached data state in case something goes wrong
        queryClient.setQueryData(
          ["applications-details", applicationId, companySlug],
          context?.cachedApplication,
        );

        return error;
      },
    },
  );
}

/**
 *
 * Unhides an application that was previously marked as "hidden".
 *
 * Usable only by Super Admins
 *
 */
export function useApplicationUnhide() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;
  const queryClient = useQueryClient();

  return useMutation(
    async (applicationId: string) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/applications/${applicationId}/unhide`,
      );
    },
    {
      onMutate: applicationId => {
        // Get the targeted application from the cached data
        const cachedApplication = queryClient.getQueryData([
          "applications-details",
          applicationId,
          companySlug,
        ]) as ApplicationDetailsFields;

        // Mark the application as hidden and move it to "Archived" bucket
        const updatedApplication: ApplicationDetailsFields = {
          ...cachedApplication,
          is_hidden: false,
        };
        queryClient.setQueryData(
          ["applications-details", applicationId, companySlug],
          updatedApplication,
        );

        return { cachedApplication };
      },
      onSuccess: () => toast.success("Application unhidden!"),
      onError: (error, applicationId, context) => {
        // Return to previous cached data state in case something goes wrong
        queryClient.setQueryData(
          ["applications-details", applicationId, companySlug],
          context?.cachedApplication,
        );

        return error;
      },
    },
  );
}

/**
 *
 * Get a list of all applications sources
 *
 */
export function useApplicaitonsGetSources() {
  return useQuery(["applications-sources"], async () => {
    return (await fetchHandler("GET", "admin/applications/sources")) as ApplicationsSourcesFields[];
  });
}

/**
 *
 * Updates the application hiring date
 *
 */
export const useApplicationUpdateHiredDate = () => {
  const { user } = useAuth();
  const companySlug = user.active_company.slug;
  const queryClient = useQueryClient();

  // Handle the query key responsible for viewing "All" or "Recent" data
  const [searchParametersObject] = useExtractSearchParameters();
  const all_data = searchParametersObject?.all_data === "true" ? true : false;

  return useMutation(
    async ({ applicationId, newDate }: { applicationId: number; newDate: string }) => {
      return await fetchHandler(
        "POST",
        `company/${companySlug}/applications/${applicationId}/hire`,
        { date: newDate },
      );
    },
    {
      onMutate: ({
        applicationId,
        newDate,
        actionType,
      }: {
        applicationId: number;
        newDate: string;
        actionType: "update" | "add";
      }) => {
        /*===============================
          INDIVIDUAL APPLICATION DETAILS
        ================================*/
        const cachedApplicationDetails = queryClient.getQueryData([
          "applications-details",
          String(applicationId),
          companySlug,
        ]) as ApplicationDetailsFields;

        if (cachedApplicationDetails && Object.entries(cachedApplicationDetails).length) {
          const copyCached = cloneDeep(cachedApplicationDetails);
          copyCached.hired_date = newDate;

          queryClient.setQueryData(
            ["applications-details", String(applicationId), companySlug],
            copyCached,
          );
        }

        /*===============================
          APPLICATIONS LIST
        ================================*/
        const cachedApplications = queryClient.getQueryData([
          "applications",
          companySlug,
          { all_data },
        ]) as ApplicationsResponseFields[];

        // Find the index of the application that was attempted to update
        if (cachedApplications && cachedApplications.length) {
          const indexToUpdate = cachedApplications.findIndex(
            application => application.id === applicationId,
          );

          // Copy the cached applications & update the single application hired date field using the new date
          const updatedApplications = cloneDeep(cachedApplications);
          updatedApplications[indexToUpdate].hired_date = newDate;

          // Update the cached data
          queryClient.setQueryData(
            ["applications", companySlug, { all_data }],
            updatedApplications,
          );
        }

        toast.success(
          actionType === "add"
            ? "Application hired date added!"
            : "Application hired date updated!",
          { toastId: "hired-date-update" },
        );

        return { cachedApplications, cachedApplicationDetails };
      },
      onError: (error, data, context) => {
        toast.dismiss("hired-date-update");

        // Return to previous cached data state in case something goes wrong
        // for both individual application details cache and all applications cache
        queryClient.setQueryData(
          ["applications", companySlug, { all_data }],
          context?.cachedApplications,
        );
        queryClient.setQueryData(
          ["applications-details", String(data.applicationId), companySlug],
          context?.cachedApplicationDetails,
        );

        return error;
      },
    },
  );
};

/**
 *
 * Get the actions history log for the targeted application
 *
 * @param applicationId The ID of the targeted application
 *
 */
export const useApplicationHistory = (applicationId: string | undefined) => {
  const { user } = useAuth();
  const companySlug = user.active_company.slug;

  return useQuery(
    ["application-history", applicationId],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/applications/${applicationId}/log`,
      )) as ApplicantHistoryResponseFields[];
    },
    { enabled: !!applicationId, retry: 0 },
  );
};

/**
 *
 * Remove the application photo for the selected application
 *
 */
export function useApplicationRemovePhoto() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (applicationId: number) => {
      return await fetchHandler(
        "DELETE",
        `company/${companySlug}/applications/${applicationId}/photo`,
      );
    },
    {
      onSuccess: () => toast.success("Application photo removed!"),
      onError: error => error,
      onSettled: (_data, _error, applicationId) => {
        queryClient.invalidateQueries([
          "applications-details",
          applicationId.toString(),
          companySlug,
        ]);
      },
    },
  );
}

/**
 *
 * Remove the application resume for the selected application
 *
 */
export function useApplicationRemoveResume() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (applicationId: number) => {
      return await fetchHandler(
        "DELETE",
        `company/${companySlug}/applications/${applicationId}/resume`,
      );
    },
    {
      onSuccess: () => toast.success("Application resume removed!"),
      onError: error => error,
      onSettled: (_data, _error, applicationId) => {
        queryClient.invalidateQueries([
          "applications-details",
          applicationId.toString(),
          companySlug,
        ]);
      },
    },
  );
}

/*
 * Marks the selected applications as hidden and moves them to
 * "Archived" bucket automatically.
 *
 * Usable only by Super Admins
 *
 */
export function useApplicationsBulkHide() {
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;
  const queryClient = useQueryClient();

  // Handle the query key responsible for viewing "All" or "Recent" data
  const [searchParametersObject] = useExtractSearchParameters();
  const all_data = searchParametersObject?.all_data === "true" ? true : false;

  return useMutation(
    async (selectedApplicationsIds: number[]) => {
      return await fetchHandler("POST", `company/${companySlug}/applications/bulk-hide`, {
        application_ids: selectedApplicationsIds,
      });
    },
    {
      onMutate: selectedApplicationsIds => {
        const cachedApplicationsData = queryClient.getQueryData([
          "applications",
          companySlug,
          { all_data },
        ]) as ApplicationsResponseFields[];

        if (cachedApplicationsData) {
          // Clone the existing query data
          const updatedApplications = cloneDeep(cachedApplicationsData);

          // Go trough each of the selected applications and update their cached data
          selectedApplicationsIds.forEach(id => {
            const targetedApplicationIndex: number = cachedApplicationsData.findIndex(
              application => {
                return application.id === id;
              },
            );

            // Prevent updates to the cached data if application cannot be found for some reason
            if (targetedApplicationIndex < 0) return;

            // Update each individual applications 'is_hidden' & 'buckets' property
            updatedApplications[targetedApplicationIndex].is_hidden = true;
            updatedApplications[targetedApplicationIndex].buckets = ["Archived"];
          });

          // Update the cached data
          queryClient.setQueryData(
            ["applications", companySlug, { all_data }],
            updatedApplications,
          );
        }

        return { cachedApplicationsData };
      },
      onError: (error, _data, context) => {
        // If something goes wrong and there's cached "Applications" data, revert it to its original state
        if (context?.cachedApplicationsData) {
          queryClient.setQueryData(
            ["applications", companySlug, { all_data }],
            context.cachedApplicationsData,
          );
        }

        return error;
      },
      onSuccess: (_data, variables) => {
        toast.success(
          `Successfully hidden ${
            variables.length > 1
              ? `${variables.length} selected applications!`
              : "1 selected application!"
          } `,
        );
      },
    },
  );
}

/**
 *
 * Generate/Regenerate an AI Resume summary for the targeted applicant
 *
 */
export function useApplicationRegenerateResumeAiSummary() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async ({ encrypted_application_id }: ApplicantRegenerateAiResumeSummaryData) => {
      return await fetchHandler(
        "POST",
        `admin/regenerate-resume-summary-data/${encrypted_application_id}`,
      );
    },
    {
      onMutate: ({ applicationID }: ApplicantRegenerateAiResumeSummaryData) => {
        // Get old query data
        const oldQueryData = queryClient.getQueryData([
          "applications-details",
          applicationID.toString(),
          companySlug,
        ]) as ApplicationDetailsFields;

        // On mutation update only the fields regarding the AI summary to trigger the 'Loading' UI
        queryClient.setQueryData(["applications-details", applicationID.toString(), companySlug], {
          ...oldQueryData,
          ai_resume_summary: {
            ...oldQueryData.ai_resume_summary,
            is_resume_regenerated: true,
            last_regenerated_resume_date: Date.now(),
            resume_summary: "",
          },
        });

        toast.success(
          `Resume Summary is being ${
            oldQueryData.ai_resume_summary &&
            oldQueryData.ai_resume_summary.last_regenerated_resume_date
              ? "regenerated"
              : "generated"
          }`,
          { toastId: "resume-summary-generation" },
        );

        return { oldQueryData };
      },
      onError: (_error, data, context) => {
        if (context?.oldQueryData) {
          queryClient.setQueryData(
            ["applications-details", data.applicationID.toString(), companySlug],
            context.oldQueryData,
          );
        }

        toast.dismiss("resume-summary-generation");
      },
    },
  );
}

/**
 *
 * Generate/Regenerate an AI SBCA summary for the targeted applicant
 *
 */
export const useApplicationRegenerateSbcaAiSummary = () => {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async ({ sbcaIdentifier }: ApplicantRegenerateAiSbcaSummaryData) => {
      return await fetchHandler("POST", `admin/regenerate-sbca-summary-data/${sbcaIdentifier}`);
    },
    {
      onMutate: ({ applicationID }: ApplicantRegenerateAiSbcaSummaryData) => {
        // Get old query data
        const oldQueryData = queryClient.getQueryData([
          "applications-details",
          applicationID.toString(),
          companySlug,
        ]) as ApplicationDetailsFields;

        // On mutation update only the fields regarding the AI summary to trigger the 'Loading' UI
        queryClient.setQueryData(["applications-details", applicationID.toString(), companySlug], {
          ...oldQueryData,
          ai_sbca_summary: {
            ...oldQueryData.ai_sbca_summary,
            is_sbca_regenerated: true,
            last_regenerated_sbca_date: Date.now(),
            sbca_summary: "",
          },
        });

        toast.success(
          `SBCA Summary is being ${
            oldQueryData.ai_sbca_summary && oldQueryData.ai_sbca_summary.last_regenerated_sbca_date
              ? "regenerated"
              : "generated"
          }`,
          { toastId: "sbca-summary-generation" },
        );

        return { oldQueryData };
      },
      onError: (_error, data, context) => {
        if (context?.oldQueryData) {
          queryClient.setQueryData(
            ["applications-details", data.applicationID.toString(), companySlug],
            context.oldQueryData,
          );
        }

        toast.dismiss("sbca-summary-generation");
      },
    },
  );
};
